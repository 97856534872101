<!-- reference https://blog.csdn.net/wwyxjjz/article/details/128028357 -->

<template>
    <div class="shareBox">
        分享到：
        <span class="iconfont hover-pointer" @click="shareToMicroblog()">
            <img src="@/icon/weibo.png" alt="" />
        </span>
        <span class="iconfont qqIcon hover-pointer" @click="shareToQQRom()">
            <img src="@/icon/QQkongjian.png" alt="" />
        </span>
        <span class="iconfont hover-pointer" @click="shareToWeChat()">
            <img src="@/icon/weixin.png" alt="" />
        </span>
    </div>
</template>
   
<script>
export default {
    props: ["sysInfo"],  //父组件传过来的文章数据
    data() {
        return {
            shareUrl: location.href,
        };
    },
    methods: {
        /**
         * 分享到微博
         */
        shareToMicroblog() {
            //跳转地址
            window.open(
                "https://service.weibo.com/share/share.php?url=" +
                encodeURIComponent(this.shareUrl) +
                "&title=" +
                this.sysInfo
            );
        },
        /**
         * 分享到qq空间
         */
        shareToQQRom() {
            //跳转地址
            window.open(
                "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
                encodeURIComponent(this.shareUrl) +
                "&title=" +
                this.sysInfo +
                "&summary=" +
                this.sysInfo
            );
        },
        /**
         * 分享到微信：需要单独画页面
         */
        shareToWeChat() {
            localStorage.setItem("shareUrl", this.shareUrl); // 存储当前页面地址，前端页面直接根据URL链接生成二维码
            let url = location.href.split("#")[0] + "#/Share/toWechat"; // 注意：#/Share/toWechat: 自己定义的路由
            window.open(url);
        },
    },
};
</script>
   
<style lang="less" scoped>
.shareBox {
    .iconfont {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin:.2rem  .4rem;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
  
  