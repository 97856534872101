<template>
  <!-- banner-->
  <div class="agileits-banner jarallax" style="font-size: 14px">
    <div class="bnr-agileinfo">

      <!-- navigation-->
      <div class="top-nav w3-agiletop">
        <div class="container">
          <div class="navbar-header w3llogo">
            <h1 style="font-size: 1.8em; transform: translateY(15px)">
              <a @click="jumpPage('/')">智辉山河教育</a>
            </h1>
          </div>
          <div class="navbar-collapse">
            <div class="w3menu navbar-right">
              <ul class="nav navbar">
                <li>
                  <a class="active" @click="jumpPage('/')">首页</a>
                </li>
                <li>
                  <a @click="jumpPage('HomeIndexNewFeature')">产品特色</a>
                </li>
                <li>
                  <a @click="jumpPage('reportCenter')">报告中心</a>
                </li>
                <li>
                  <a @click="jumpPage('ConsultUs')">报告解读与生涯服务咨询</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- //navigation-->

      <!-- banner-text-->
      <div class="banner-w3text">
        <div class="container">
          <ul id="vertical-ticker">
            <li>国内唯一拥有国家专利的专业定位产品</li>
            <li>7 个专业定位报告</li>
            <li>9 大中学学科能力测评</li>
            <li>专业定位准确率超 80%</li>
            <li>92 个大类专业，703 个小类专业全匹配</li>
            <li>双一流大学专业全把握</li>
          </ul>
          <div class="w3ls-bnr-icons social-icon">
            <a id="btn-left" @click="jumpPage('HomeIndexSubjectindex')"
              ><span>进入学科能力测评系统&nbsp;</span
              ><i class="el-icon-right"></i></a
            ><a id="btn-right" @click="jumpPage('HomeIndexMajorIndex')"
              ><span>进入智能专业定位系统&nbsp;</span
              ><i class="el-icon-right"></i
            ></a>
            <a id="btn-right" @click="jumpPage('HomeIndexXuankeIndex')"
              ><span>进入新高考选科(3+1+2)&nbsp;</span
              ><i class="el-icon-right"></i
            ></a>
          </div>
        </div>
      </div>
      <!-- //banner-text-->


      <!-- <share-com></share-com> -->
      
      <index-footer></index-footer>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import './../../../Public/utils/new-index/js/jarallax'
import './../../../Public/utils/new-index/js/jquery.totemticker'
import './../../../Public/utils/new-index/js/move-top'
import './../../../Public/utils/new-index/js/easing'
import IndexFooter from './com/indexfooter.vue'
import ShareCom from './../../../components/share.vue'

$(function () {
  $('#vertical-ticker').totemticker({
    row_height: '75px',
    next: '#ticker-next',
    previous: '#ticker-previous',
    stop: '#stop',
    start: '#start',
    mousestop: true
  })
})

$(document).ready(function ($) {
  $('.scroll').click(function (event) {
    event.preventDefault()
    $('html,body').animate(
      {
        scrollTop: $(this.hash).offset().top
      },
      1000
    )
  })
})

$(document).ready(function () {
  $().UItoTop({
    easingType: 'easeOutQuart'
  })
})

export default {
  name: 'HomeIndexIndexPage',
  data: function () {
    return {}
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.state.User.haslogin
    }
  },
  methods: {
    jumpPage: function (dest) {
      if (dest == '/') return
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    scrollToTop: function () {
      if (!document.body.scrollTop || !document.documentElement.scrollTop)
        if (document.documentElement.scrollTop)
          var l = setInterval(function () {
            ;(document.documentElement.scrollTop =
              0.6 * document.documentElement.scrollTop),
              document.documentElement.scrollTop < 1 &&
                (clearInterval(l), (document.documentElement.scrollTop = 0))
          }, 30)
        else if (document.body.scrollTop)
          var n = setInterval(function () {
            ;(document.body.scrollTop = 0.6 * document.body.scrollTop),
              document.body.scrollTop < 1 &&
                (clearInterval(n), (document.body.scrollTop = 0))
          }, 30)
    }
  },
  mounted: function () {
    this.scrollToTop()
  },
  components: {
    IndexFooter,ShareCom
  }
}
</script>

<style scoped>
@import url('./../../../Public/resources/css/templates/home/index/newindex.min.css');
@import url('./../../../Public/utils/element-ui/index.css');
@import url('./../../../Public/utils/new-index/css/index-style.less');
@import url('./../../../Public/utils/new-index/css/style.css');

@media (max-width: 480px) {
  .navbar li {
    text-align: right;
  }
}
</style>
